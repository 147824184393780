// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
}

button {
  width: 50%;
  padding: 10px;
  margin: 5px 0;
  color: white;
  background-color: #37caf7;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

body {
  background-color: #0014f3; /* Fallback color */
  background-image: linear-gradient(to bottom right, #0014f3, #004b84);
  min-height: 100vh;
  margin: 0;
  padding: 0;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB,EAAE,mBAAmB;EAE9C,oEAAoE;EACpE,iBAAiB;EACjB,SAAS;EACT,UAAU;EACV,YAAY;AACd","sourcesContent":[".App {\n  text-align: center;\n  margin: 0 auto;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-size: 1.4rem;\n}\n\nbutton {\n  width: 50%;\n  padding: 10px;\n  margin: 5px 0;\n  color: white;\n  background-color: #37caf7;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\nbody {\n  background-color: #0014f3; /* Fallback color */\n  background-image: -webkit-linear-gradient(to bottom right, #0014f3, #004b84);\n  background-image: linear-gradient(to bottom right, #0014f3, #004b84);\n  min-height: 100vh;\n  margin: 0;\n  padding: 0;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
